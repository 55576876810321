<template>
<div class="background-image-banner">
  <div class="container align-middle align-items-center" id="property-search-container" style="height: 100vh;
    display: flex;">
    <CCard>
      <CCardBody>
        <div class="panel panel-default">
          <div class="panel-body">
            <CRow class="">
              <CCol col="12" class="text-center mb-3">
              <h1>What is your home worth?</h1>
              </CCol>
            </CRow>
            <CRow class="row">
              <CCol col="6" class="col-sm-6">
                <div>Hi! We are {{ agent1.name }} and {{ agent2.name }} and we would like to offer you a complimentary
                  home valuation and quick peek at the market activity near your home. Register below to get your FREE
                  valuation.
                </div>
                <hr />
                <AgentInfo :agent="agent1" />
                <hr />
                <AgentInfo :agent="agent2" />
              </CCol>
              <CCol col="6" class="col-sm-6 bg-light border-secondary text-center p-3">
                <div class="well">
                  <h2 class="">Get your free home valuation</h2>
                  <ValuationForm layout="horizontal"></ValuationForm>
                </div>
              </CCol>
            </CRow>
          </div>
        </div>
      </CCardBody>
    </CCard>
  </div>
</div>
</template>

<script>
import AgentInfo from '@/components/AgentInfo';
import ValuationForm from '@/components/forms/ValuationForm';

export default {
  name: 'PropertySearch',
  props: {
    agent1: Object,
    agent2: Object,
  },
  components: {
    AgentInfo: AgentInfo,
    ValuationForm: ValuationForm,
  },
  beforeMount() {
    const contactId = this.$route.params.contact_id;
    //this.$store.dispatch('Contact/recordVisit', contactId);
  },
};
</script>

<style scoped>
</style>